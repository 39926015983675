<template>
	<v-container grid-list-xl >
		<v-row>
			<v-col cols="12" md="10">
				<v-card>
					<v-card-title primary-title>
						<v-btn @click="crearPDF()"><v-icon>print</v-icon></v-btn>
						<v-btn @click="regresar"><v-icon>reply</v-icon></v-btn>
					</v-card-title>


					<v-card-text> 
						<div id="factura">
							<header>
								<div id="logo">
									<img :src="`${logoBase64}`" id="imagen">
								</div>
								<div id="datos">
									<div align="center" class="text--center"><h1>{{ tipo }} {{Docum.Numdoc}} </h1></div>

										<div class="text-xs-left font-weight-black">{{ getCIA.rfccia }}</div>
										<div>{{ getCIA.calle + ' ' + getCIA.numext + ' ' + getCIA.colonia }} </div>
										<div>{{getCIA.ciudad + ' ' + getCIA.estado  }} </div>
										<div>CP {{getCIA.cp}}  Telefono:  {{getCIA.telefono}}</div>
								</div>
							</header>
		
						<section>
							<div>
							<table id="facliente" >
								<tbody>
								<tr>
									<td id="cliente" WIDTH="60%" >
									<tbody>
											<tr>
												<td id="cliente">
													<div>CLIENTE </div>
														<strong>RFC {{ Clientes.Rfc }}</strong><br>
															{{ Clientes.Nomcli }}<br>
															{{ Clientes.Calle}} {{Clientes.Numext}} {{Clientes.Colonia}}<br>
															{{ Clientes.Ciudad}} {{Clientes.Estado}} <br>
												</td>
											</tr>
									</tbody>
									</td>
									<td id="cliente" WIDTH="35%" >
									<tbody>
											<tr>
												<td id="cliente" >
												
												<strong>Moneda: </strong>MXN<br>
												<strong><div>Fecha: {{Docum.Fecha}}  </div></strong><br>
												Email: {{ Clientes.Email1 }}<br>
															Telefono: {{ Clientes.telefono }}<br>
												</td>
											</tr>
											</tbody>
									</td>
								</tr>
								</tbody>
							</table>
							</div>
						</section>
						<br>
						<section>
							<div>
							<table id="facarticulo" >
								<thead>
								<tr id="fa">
									<th style="width: 4%;">ID</th>
									<th style="text-align:center; width: 10%;">Clave</th>
									<th style="text-align:left; width: 40%;">Descripción</th>
									<th style="text-align:center; width: 5%;">Cant</th>
									<th style="text-align:center; width: 5%;">Und</th>
									<th style="text-align:center; width: 8%;">Precio</th>
									<th style="text-align:center; width: 4%;">Descuento</th>

									<th style="text-align:right; width: 8%;">Importe</th>
									<th style="text-align:right; width: 10%;">Subtotal</th>
									<th style="text-align:center; width: 12%;">Neto</th>
								</tr>
									</thead>
										<tbody>
											<tr id="fs" v-for="carrito in MovinArray" :key="carrito.clave" >
											<td style="text-align:center;">{{ carrito.Numpar     }}</td>
											<td style="text-align:left;">{{ carrito.Numart      }}</td>
											<td style="text-align:left;">{{ carrito.Descrip      }}</td>
											<td style="text-align:center;">{{ carrito.Cant        }}</td>
											<td style="text-align:center;">{{ carrito.Unidad      }}</td>
											<td style="text-align:right;">{{ carrito.Precio      }}</td>
											<td style="text-align:center;">{{ carrito.Pjedesc     }}</td>

											<td style="text-align:right;">{{ carrito.Importe     }}</td>
											<td style="text-align:right;">{{ carrito.Subtotal    }}</td>
											<td style="text-align:right;">{{ carrito.Total       }}</td>
											</tr>
										</tbody>
									<br>
									<br>
										<tfoot>
										<tr>
										<th></th>
										<th></th>
										<th></th>
										<th></th>
										<th></th>
										<th></th>
										<th></th>

										<th style="text-align:right;">Importe</th>
										<th></th>
										<th style="text-align:right;">{{Importe}}</th>
										</tr>

										<tr >

										<th></th>
										<th></th>
										<th></th>
										<th></th>
										<th></th>
										<th></th>
										<th></th>
										<th style="text-align:right;">Descuento</th>
										<th></th>
										<th style="text-align:right;">{{Descuento}}</th>
										</tr>

						<tr>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>

						<th style="text-align:right;">Sub Total</th>
						<th></th>
						<th style="text-align:right;">{{Subtotal}}</th>
						</tr>

						<tr>

						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th style="text-align:right;">Impuesto</th>
						<th></th>
						<th style="text-align:right;">{{Iva}}</th>
						</tr>
						<tr>

						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th style="text-align:right;">Total</th>
						<th></th>
						<th style="text-align:right;">{{Total}}</th>
						</tr>
						</tfoot>
						</table>
						</div>
						</section>		
						<br>
						<br>

						<footer>
						<div id="gracias">
						<p><b>{{ condicom }}</b></p>
						</div>
						</footer>

						<footer>
						<div id="condiciones" style="position:absolute; bottom:250px; left:35px;">
						<p><b>Condiciones de pago: Contado</b></p>
						</div>
						</footer>
						<v-spacer></v-spacer>
						<footer>
						<div id="cuentas" style="position:absolute; bottom:5px; left:35px;">
						<p style=""><b>Para su mayor comodidad puede realizar su
						deposito o transferencia a nombre de
						{{getCIA.nomcia}} en:</b></p>
						
						<textarea rows="10" cols="50"></textarea>
						
						</div>
						</footer>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
  </v-container>	
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
	// import jsPDF from 'jspdf'
	// import html2canvas from 'html2canvas';
	// import autoTable from 'jspdf-autotable'

	var accounting = require("accounting");
	var base64 = require('base-64');

	export default{
		data(){
			return{
				cuentas:'',
				condicom:'',
				logoBase64:'',
				tipo:'',
				Importe:0,
				Descuento:0,
				Subtotal:0,
				Iva:0,
				Total:0,
				MovinArray:[{}],
				tipodoc: '',
				loading:true,
				Clientes:{},
				Movim: [{}],
				Docum: '',
				radios: 'radio-1',
				dialog: false,
				total:'',
				direccion:'',
				headers: [
					{ text: 'Clave'			   ,align: 'center', value: 'Numart'	    ,sortable: false },
					{ text: 'Descripción'  ,align: 'center', value: 'Descrip'     ,sortable: false },
					{ text: 'Cant'	   ,align: 'center', value: 'Cant'		    ,sortable: false },
					{ text: 'Und'	     ,align: 'center', value: 'Unidad'	    ,sortable: false },
					{ text: 'Precio'		   ,align: 'right',  value: 'Precio'      ,sortable: false },
					{ text: 'Pje Desc'	   ,align: 'right',  value: 'Pjedesc'     ,sortable: false },
					// { text: 'Impuesto'	   ,align: 'right',  value: 'Impuesto1'   ,sortable: false },
					{ text: 'Importe'		   ,align: 'right',  value: 'Importe'     ,sortable: false },
					{ text: 'Subtotal'		 ,align: 'right',  value: 'Subtotal'    ,sortable: false },
					// { text: 'Neto'  ,align: 'right',  value: 'Total'       ,sortable: false }
					],
				comprobanteModal: true

			}
		},

	created () {

		this.$http.get('auth/api/v1/formatos').then(respuesta=>{
			this.cuentas = respuesta.body.Ctadeps
			this.condicom = respuesta.body.Condicom
			console.log('normal',this.cuentas)
			console.log('anormal',this.cuentas.replace(" ", "\t\t") )
			this.cuentas.replace(" ", "\t\t")
		})

		this.getLogoBase()

		// Consultar cotización
		console.log(this.getDocumento)

		let iddocum = ''
		// RECIBE PARAMETROS POR ROUTER
		if(this.getDocumento.Iddocum == undefined){
		// si no manda parametros rechaazar 
		tipodoc = this.$route.params.info.doc
		var bytes = base64.decode(tipodoc);
		iddocum = bytes

		}else{

		iddocum = this.getDocumento.Iddocum
		}

		var tipodoc = this.getDocumento.Iddocum

		if(this.getDocumento.Tipodoc == ' P'){
		this.tipo = 'Pedido'
		}else{
		this.tipo = 'Cotización'
		}


		if (iddocum) {
		this.init(iddocum);	
		}

	},

	computed: {
	...mapGetters('carrito',['getCarrito','getTC']),
	...mapGetters('documento',['getDocumento']),
	...mapGetters('Login',['getdatosUsuario','getClientes','getCliente','getCIA','getModo']),
	},


methods: {
	...mapActions('documento',['addDocumento']),

	getLogoBase(){
		this.$http.get('api/v1/logos.list').then(response=>{
			const toDataURL = url => fetch(url)
			.then(response => response.blob())
			.then(blob => new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.onloadend = () => resolve(reader.result)
			reader.onerror = reject
			reader.readAsDataURL(blob)
		}))

		toDataURL(response.body.Logourl)
		.then(dataUrl => {
		this.logoBase64 = dataUrl
		document.getElementById("imagen").setAttribute("src", dataUrl);
		})
		}, error => {console.log(error)})
	},

	regresar(){
		console.log(this.$route.params)
		if(this.$route.params.info == undefined){
		this.$router.push({name:'documento'})
		}else{
		let tipodoc = this.$route.params.info.doc
		this.$router.push({name: this.$route.params.origen, params:{info:this.$route.params.info, origen: this.$route.params.origen, tipodoc: tipodoc}})
		}
	},

	// crearPDF(){
	// 	var cNomFile = this.Docum.Numdoc.trim()  + '.pdf'
	// 	console.log(cNomFile) 
	// 	var quotes = document.getElementById('factura');
	// 	html2canvas(quotes).then(function (canvas) {
	// 	var imgData = canvas.toDataURL('image/png');
	// 	var imgWidth = 205;
	// 	var pageHeight = 295;
	// 	var imgHeight = canvas.height * imgWidth / canvas.width;
	// 	var heightLeft = imgHeight;
	// 	var doc = new jsPDF();
	// 	var position = 0;
	// 	doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);    
	// 	// doc.save('ComprobanteVenta.pdf');
	// 	doc.save(cNomFile)
	// 	});
	// },

	init (tipodoc) {
		console.log("TIPODOC",tipodoc)
		this.loading = true

		this.$http.get('api/v1/order.info/' + tipodoc).then(response=>{
			console.log(response)
			var respuesta = response.body
			var payload = {Iddocum: respuesta.Iddocum, Tipodoc: respuesta.Tipodoc}

			//QUE CHINGADOS MANDA HACER ESTO? 
			this.addDocumento(payload)
			console.log(respuesta)

			if(respuesta.Tipodoc.trim() == 'P'){
			this.tipodoc = 'Pedido'
			}else{
			this.tipodoc = 'Cotización'
			}

			respuesta.Importe = accounting.unformat(respuesta.Importe)
			respuesta.Impuesto1 = accounting.unformat(respuesta.Impuesto1)

			this.total = accounting.formatNumber( (parseFloat(respuesta.Importe) + parseFloat(respuesta.Impuesto1)),2)
			respuesta.Importe = accounting.formatNumber(respuesta.Importe, 2)
			respuesta.Impuesto1 = accounting.formatNumber(respuesta.Impuesto1, 2)

			this.Docum    = respuesta
			this.Clientes = respuesta.Clientes[0]
			this.Movim    = respuesta.Movim
			this.direccion= this.Clientes.Calle + ', ' + this.Clientes.Numint + ', ' + this.Clientes.Nuthisxt + ', ' + this.Clientes.colonia + ', ' + this.Clientes.Ciudad + ', ' + this.Clientes.Estado + ', ' + this.Clientes.Cp + ', ' + this.Clientes.Pais

			this.crearArreglo()
		}).catch(function(error){
			console.log(error)
		}).finally(()  => this.loading = false) 

	},

	crearArreglo(){

		////////////////////////////////((((((((((((((((()))))))))))))))))
		this.Movim.forEach(element=> {
		element.Precio   = accounting.unformat(element.Precio)
		element.Impuesto1= accounting.unformat(element.Impuesto1)
		element.Impuesto2= accounting.unformat(element.Impuesto2)
		element.Importe  = accounting.formatNumber(element.Cant * accounting.unformat(element.Precio),2)
		element.Iva  = 0.00
		element.Total= 0.00
		})

		this.MovinArray = this.Movim
		this.longitud= this.MovinArray.length


		for (var i = this.MovinArray.length - 1; i >= 0; i--) {

		if(this.MovinArray[i].Impuesto1 !== 0){
			//si tienes descuento
			if(this.MovinArray[i].Pjedesc !== 0){
				//se aplica el descuento
				var desc = 1 - (parseFloat(this.MovinArray[i].Pjedesc) / 100)
				//se aplica el Impuesto1
				var ivaiva =  (this.MovinArray[i].Impuesto1 / 100)
				var ivaarticulo = 1 + (this.MovinArray[i].Impuesto1 / 100)
				//y ahora si se aplica prithisro al articulo el descuento y luego el Impuesto1
				this.MovinArray[i].Total =  accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * desc * ivaarticulo),2)

				this.Descuento = this.Descuento + ((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * (parseFloat(this.MovinArray[i].Pjedesc) / 100) )

				this.MovinArray[i].Iva = accounting.formatNumber( ((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * ivaiva),2)

				this.MovinArray[i].Subtotal = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant)  * desc),2)

				this.Iva = this.Iva + accounting.unformat(this.MovinArray[i].Subtotal) * ivaiva
			}else{
				//en caso de que no tenga descuento
				ivaiva =  (this.MovinArray[i].Impuesto1 / 100)
				ivaarticulo = 1 + (this.MovinArray[i].Impuesto1 / 100)
				this.iva = this.iva + (this.MovinArray[i].Precio * this.MovinArray[i].Cant) * ivaiva
				this.MovinArray[i].Total = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant)  * ivaarticulo),2)
				this.MovinArray[i].Iva =  accounting.formatNumber( ((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * ivaiva),2)
				this.MovinArray[i].Subtotal =  accounting.formatNumber( (this.MovinArray[i].Precio * this.MovinArray[i].Cant),2)
				this.Iva = this.Iva + accounting.unformat(this.MovinArray[i].Subtotal) * ivaiva
			}
		}else{
			//en caso de que no tenga Impuesto1
			if(this.MovinArray[i].Pjedesc !== 0){
				//se aplica el descuento
				desc = 1 - (parseFloat(this.MovinArray[i].Pjedesc) / 100)
				this.MovinArray[i].Total = accounting.formatNumber(((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * desc),2)
				this.MovinArray[i].Subtotal =  accounting.formatNumber( ((this.MovinArray[i].Precio * this.MovinArray[i].Cant) * desc) ,2)
				this.Descuento = this.Descuento + ((parseFloat(this.MovinArray[i].Pjedesc) / 100) * (this.MovinArray[i].Precio * this.MovinArray[i].Cant))

			}else{
				this.MovinArray[i].Total = accounting.formatNumber((this.MovinArray[i].Precio * this.MovinArray[i].Cant),2)
			}
			//Formato
		}
		this.MovinArray[i].Precio = accounting.formatNumber(this.MovinArray[i].Precio, 2)

		}

		for (var j = this.MovinArray.length - 1; j >= 0; j--) {
			this.Importe = this.Importe + accounting.unformat(this.MovinArray[j].Importe)
			this.Subtotal = this.Subtotal + accounting.unformat(this.MovinArray[j].Subtotal)
			this.Total = this.Total + accounting.unformat(this.MovinArray[j].Total)
		}

		this.Importe = accounting.formatNumber(this.Importe ,2)
		this.Subtotal = accounting.formatNumber(this.Subtotal ,2)
		this.Iva = accounting.formatNumber(this.Iva ,2)
		this.Total = accounting.formatNumber(this.Total ,2)
		this.Descuento = accounting.formatNumber(this.Descuento,2)
		},

	}
};
</script>

<style scoped>
#factura {
height: 1114px;
padding: 20px;
font-family: Arial, sans-serif;
font-size: 16px ;
}

#logo {
float: left;
margin-left: 2%;
margin-right: 2%;
}
#imagen {
width: 150px;
}

#fact {
font-size: 18px;
font-weight: bold;
text-align: center;
}   

#datos {
float: left;
margin-top: 0%;
margin-left: 2%;
margin-right: 2%;
/*text-align: justify;*/
}

#encabezado {
margin-left: 10px;
margin-right: 10px;
font-size: 14px;
}

section {
clear: left;
}

#cliente {
text-align: left;
font-size: 14px;
}

#facliente {
border-collapse: collapse;
border-spacing: 0;
margin-bottom: 15px;
}

#fa {
color: #FFFFFF;
font-size: 12px;
}

#fs {
font-size: 12px;
}

#facarticulo {
width: 100%;
border-collapse: collapse;
border-spacing: 0;
padding: 20px;
margin-bottom: 15px;
}

#facarticulo thead {
padding: 20px;
background: #2183E3;
text-align: center;
}

#gracias {
text-align: center;
font-size: 12px;
}

#condiciones {
text-align: left;
font-size: 12px;
}

#footersait{
background: #2183E3;
color: white;
}

#cuentas{
width: 350px;
height: 250px;

}
</style>
